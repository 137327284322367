/* //color constants
$primary: #004aad;
$primary-dark: #022135;
$primary-dark-bg: #e1f0fb;
$primary-light-bg: #fafcfe;

$gray-dark: #545454;
$gray-light: #8f8e8e;

body {
  background-color: $primary-light-bg;
}

.underlinewave {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: $primary;
  text-underline-offset: 5px;
}

//from customizations
.MuiInputBase-root {
  padding: 0;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div {
  @apply border-text-color-1;
}

input:checked + div svg {
  @apply block;
}

input:focus,
select:focus {
  outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../img/icons/date.png") no-repeat;
  width: 24px;
  height: 24px;
  border-width: thin;
}
