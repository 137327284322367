.loader-container {
  position: fixed;
  top: 0px;
  left: 0px;
  /* background: black; */
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container > .loader-background {
  background: black;
  width: 100%;
  height: 100vh;
  opacity: 0.7;
  position: absolute;
}
